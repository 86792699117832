<template>
  <v-dialog
    v-model="dialog"
    class="mr-0"
    scrollable
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tt }">
          <v-btn fab small color="color3Text color3--text" v-on="{ ...d, ...tt }">
            <v-icon small>fas fa-user-plus</v-icon>
          </v-btn>
        </template>
        <span>Team Quick Add</span>
      </v-tooltip>
    </template>

    <!-- QUICK ADD -->
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Team Quick Add</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text icon color="color1Text" @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-expand-transition leave-absolute mode="out-in">
          <tournament-registration
            ref="stepper"
            :key="dialog"
            v-if="tournament && registration && (cartStatus || user.vbl)"
            :tournament="tournament"
            :registration="registration"
            :adminQuickAdd="true"
            @complete-quick-add="onCompleteQuickAdd"
          ></tournament-registration>
          <div v-else-if="checking" class="py-9 text-center">
            <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
          </div>
          <div v-else class="pt-6">
            <v-alert type="error" prominent text>
              Your account is not complete, please connect a stripe account.
            </v-alert>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import RegistrationUI from './Registration.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['tournament', 'division', 'fab', 'small'],
  data () {
    return {
      dialog: false,
      registration: null,
      loading: false,
      checking: false,
      cartStatus: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    getCartStatus () {
      this.checking = true
      this.$VBL.cart.getStatus(this.tournament.organization.id)
        .then(r => {
          this.cartStatus = r.data.status
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.checking = false
        })
    },
    onCompleteQuickAdd (cartItem) {
      this.loading = true
      if (Array.isArray(cartItem)) {
        return this.onCompleteQuickAdd2(cartItem)
      }
      this.$VBL.team.quickAdd(cartItem)
        .then((response) => {
          // this.division.getTeams(this.$VBL)
          this.loading = false
          this.dialog = false
          this.$refs.stepper.reset()
        })
        .catch((error) => {
          console.log(error.response)
          this.loading = false
        })
    },
    onCompleteQuickAdd2 (cartItems) {
      this.loading = true
      this.$VBL.team.quickAdd2(cartItems)
        .then((response) => {
          // this.division.getTeams(this.$VBL)
          this.loading = false
          this.dialog = false
          this.$refs.stepper.reset()
        })
        .catch((error) => {
          console.log(error.response)
          this.loading = false
        })
    }
  },
  watch: {
    dialog: function () {
      this.getCartStatus()
      this.registration = this.tournament.newRegistration()
      this.registration.setDivision(this.division)
      if (this.division) {
        const windows = this.division.registrationWindows.filter(f => f.isCurrent)
        if (windows.length === 1) {
          this.registration.window = windows[0]
        } else {
          this.registration.window = null
          if (windows.length === 0) {
            this.registration.window = this.division.lastRegistrationWindow
          }
        }
      }
    }
  },
  components: {
    'tournament-registration': RegistrationUI
  }
}
</script>

<style>

</style>
