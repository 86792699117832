<template>
    <v-hover v-slot:default="{ hover }">
      <v-card :width="size" :class="{'elevation-10': hover || pin || isIntersecting || autoPin}" ref="card" v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
              }
            }">
        <!-- Pin & Bookmark -->
        <v-fab-transition>
          <div
            v-if="hover || bookmarked || pin || isIntersecting || autoPin "
            class="pa-0 d-flex justify-space-between"
            style="position: absolute; width: 100%;z-index: 4;"
          >
            <v-btn
              text icon
              @click.stop="pin = !pin"
            >
              <v-icon small :color="window === 0 ? hover || pin || isIntersecting ? 'white' : 'rgba(255,255,255,0.3)' : ''">
                fa fa-thumbtack{{pin ? '' : ' fa-rotate-90'}}
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <book-mark
              :color="window === 0 ? hover || pin || isIntersecting ? 'white' : 'rgba(255,255,255,0.3)' : ''"
              :ppid="player.id"
              type="player"
            ></book-mark>
          </div>
        </v-fab-transition>
        <!-- Bottom FABs -->
        <v-fab-transition>
          <div
            v-if="(hover || pin || isIntersecting || autoPin)"
            class="pa-3 d-flex align-end pb-5"
            style="position: absolute; bottom: 0; width: 100%;z-index: 3;"
          >
            <!-- Quick Stats -->
              <v-fab-transition>
                <v-tooltip top :key="window">
                  <span>{{window === 0 ? 'Quick Stats' : 'Photo'}}</span>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="color3Text color3--text"
                      fab small
                      v-on="on"
                      class="mr-2"
                      @click.stop="toggleWindow"
                      :key="window"
                    >
                      <v-icon>fas fa-{{window === 0 ? 'analytics' : 'portrait'}}</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-fab-transition>
            <!-- Notes -->
              <v-fab-transition>
                <v-tooltip top :key="window" v-if="window !== 2">
                  <span>Your Notes</span>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="color3Text color3--text"
                      fab small
                      v-on="on"
                      class="mr-2"
                      @click.stop="showNotes"
                      :key="window"
                    >
                      <v-icon>fas fa-comment-alt-lines</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-fab-transition>
            <!-- Share -->
              <v-tooltip top v-if="false">
                <span>Share</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="color3Text color3--text"
                    fab small
                    v-on="on"
                    href="sms:?body="
                  >
                    <v-icon>fas fa-share-alt</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            <!-- Tag -->
              <v-tooltip top  v-if="window !== 3" :key="window">
                <span>Tag</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="color3Text color3--text"
                    fab small
                    v-on="on"
                    @click.stop="showTags"
                  >
                    <v-icon>fas fa-tags</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
              <v-spacer></v-spacer>
            <!-- View -->
              <v-tooltip top>
                <span>View Profile</span>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="color3 color3Text--text"
                    fab small
                    v-on="on"
                    :to="myRoute"
                  >
                    <v-icon>fas fa-caret-right</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
          </div>
        </v-fab-transition>
        <v-window v-model="window" touchless>
          <!-- IMAGE -->
          <v-window-item :key="0">
            <!-- Image -->
            <v-img
              :class="{bw: !(hover || pin || isIntersecting || autoPin)}"
              aspect-ratio="1"
              :contain="false"
              :height="size"
              :src="player.profilePic"
              @click.stop="$router.push(myRoute)"
              :id="`card_${player.id}`"
              class="profile_image"
            >
              <!-- Overlay -->
              <v-expand-transition>
                <div
                  v-if="!(hover || pin || isIntersecting || autoPin)"
                  class="color3 v-card--reveal"
                  style="height: 100%;"
                ></div>
              </v-expand-transition>
            </v-img>
            <!-- Text -->
            <div
              class="d-flex profile_name text-center"
              :class="{hover: hover}"
              style="height: 100%;"
            >
              <div class="pb-1">
                <div>{{player.name}} {{player.isJunior && player.gradYear ? player.gradYear : null}}</div>
              </div>
              <template>
                <hr>
                <div>
                  <div v-if="player.isJunior">{{player.committedSchool}}</div>
                  <div class="pt-1 sub" v-if="player.isJunior">
                    <fit-text>{{player.club}}</fit-text>
                  </div>
                </div>
              </template>
            </div>
          </v-window-item>
          <!-- STATS -->
          <v-window-item :key="1">
            <v-responsive :aspect-ratio="1">
              <v-card class="flexcard fill-height">
                <v-card-text class="pt-6 grow pb-0">
                  <profile-display v-if="window === 1" :player="player" :quickStats="true" :loading="loading"></profile-display>
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-window-item>
          <!-- NOTES -->
          <v-window-item :key="2">
            <v-responsive :aspect-ratio="1">
              <v-card class="flexcard fill-height">
                <v-card-text class="pt-6 grow">
                  <user-notes
                    :player="player"
                    :embed="true"
                  ></user-notes>
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-window-item>
          <!-- Tags -->
          <v-window-item :key="3">
            <v-responsive :aspect-ratio="1">
              <v-card class="flexcard fill-height">
                <v-card-text class="pt-6 grow">
                  <tag-row :player="player" :noDialog="true"></tag-row>
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-window-item>
        </v-window>
        <scoped-slot-watcher :data="(hover || pin || isIntersecting || autoPin)" @change="reset"></scoped-slot-watcher>
      </v-card>
    </v-hover>
</template>

<script>
import { mapGetters } from 'vuex'
import ScopedSlotWatcher from '@/components/Utils/ScopedSlotWatcher'
import BookMark from '@/components/Player/BookMark'
const ProfileDisplay = () => import('@/components/Player/ProfileDisplay.vue')
const UserNotes = () => import('@/components/Player/UserProfileNoteList.vue')
const TagRow = () => import('@/components/Player/Tags/TagRow')

export default {
  props: ['player', 'size', 'active'],
  data () {
    return {
      activeBtn: null,
      booking: false,
      window: 0,
      pin: false,
      loading: false,
      loadedPublic: false,
      isIntersecting: false,
      myNotes: []
    }
  },
  computed: {
    ...mapGetters(['user', 'darkColor', 'theme']),
    bookmarked () {
      return this.user && this.user.bookmarks && this.user.bookmarks.find(f => f.id === this.player.id && f.type === 'player')
    },
    isUser () {
      return this.user && this.user.isPlayerAdmin(this.player.id)
    },
    myRoute () {
      const mem = !!this.player.memberId && this.theme.membership
      return {
        name: mem ? 'member-profile' : 'player-profile',
        params: {
          id: mem ? this.player.memberId : this.player.id,
          playerProfileId: this.player.id,
          memberId: this.player.memberId,
          name: this.player.name,
          profilePic: this.player.profilePic,
          male: this.player.male
        }
      }
    },
    line2 () {
      return this.player.committedSchool || this.player.gradYear
    },
    autoPin () {
      return this.window > 0
    }
  },
  methods: {
    bookMe () {
      if (!this.user) {
        this.$emit('blocked', 'You must be logged in to favorite a player')
        return
      }
      this.booking = true
      this.$VBL.user.toggleBookmark('player', this.player.id)
        .catch(e => console.log(e.response))
        .finally(() => { this.booking = false })
    },
    loadPublic () {
      this.loading = true
      this.$VBL.player.getProfile(this.player.id)
        .then(response => {
          this.player.update(response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
        .finally(() => {
          this.loadedPublic = true
          this.loading = false
        })
    },
    reset (val) {
      if (!val) {
        this.window = 0
      }
    },
    toggleWindow () {
      this.window = this.window ? 0 : 1
      this.window === 1 && !this.loadedPublic && !this.loading && this.loadPublic()
      this.$refs.card.click()
    },
    showNotes () {
      if (!this.user) {
        this.$emit('blocked', 'You must be logged in to take notes')
        return
      }
      this.window = 2
    },
    showTags () {
      if (!this.user) {
        this.$emit('blocked', 'You must be logged in to take notes')
        return
      }
      this.window = 3
    },
    onIntersect (entries, observer) {
      this.isIntersecting = entries[0].intersectionRatio >= 0.75 && this.$vuetify.breakpoint.smAndDown
    }
  },
  watch: {
    active: function (v) {
      !v && this.reset()
    }
  },
  components: {
    ScopedSlotWatcher,
    ProfileDisplay,
    UserNotes,
    BookMark,
    TagRow
  }
}
</script>

<style scoped>
.bw >>> .v-image__image {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .7;
  position: absolute;
  width: 100%;
}
.profile_name {
  align-items: center;
  bottom: 0;
  justify-content: flex-end;
  opacity: 1;
  position: absolute;
  width: 100%;
  font-size: 2em !important;
  flex-direction: column;
  z-index: 1;
  font-weight: 300;
  color: #FFFFFF !important;
}
.profile_name div:last-child {
  height: 40%;
}
.profile_name div {
  width: 100%;
  line-height: initial;
}
.profile_name hr {
  width: 50%;
  border-style: solid;
}
.profile_name .sub {
  font-size: .5em;
}
.stats .name {
  font-size: 2em !important;
}
.profile_name.hover {
  color: rgba(255, 255, 255, 0.5) !important;
}
</style>
