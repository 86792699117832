<template>
  <div>
    <v-container fluid class="pt-0">
      <v-row dense v-if="view.public && !teams.length">
        <v-col cols="12">
          <message-card>
            <h2 class="ma-12 text-center">
              <v-avatar color="red">
                <v-icon color="white">fas fa-eye-slash</v-icon>
              </v-avatar>
              <br class="hidden-md-and-up">
              The tournament host has not published the teams yet.
            </h2>
          </message-card>
        </v-col>
      </v-row>
      <v-row dense v-else>
        <v-col class="text-right" cols="12" v-if="hasBookmarks">
          <v-switch
            label="Favorite Players Only"
            v-model="book"
            color="success"
            dense hide-details
          ></v-switch>
        </v-col>
        <v-col class="text-right pa-0" cols="12" v-if="view.adminAndRefs">
          <v-btn
            color="color3"
            x-small text
            @click.stop="playerCheckIn = !playerCheckIn"
          >
            {{playerCheckIn ? 'hide' : 'show'}} Player check-in
          </v-btn>
        </v-col>
        <v-col cols="12" :key="payData.length">
          <!-- LIST VIEW -->
          <v-data-table
            v-if="listView"
            :items="teams"
            :headers="headers"
            :loading="isLoading"
            :options.sync="page"
            :search="search"
            class="elevation-1"
            :hide-default-header="$vuetify.breakpoint.xs"
            dense
            @current-items="getFiltered"
          >
            <template v-slot:progress>
              <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`item.team.name`]="{item}">
              <v-icon small color="error" class="mr-1" v-if="payLater && payData.length && isPending(item.team.id)">fas fa-sack-dollar</v-icon>
              {{item.team.name}}
              <v-icon small color="error" class="ml-2" v-if="payLater && payData.length && isPending(item.team.id) && isQuickAdd(item.team.id)">fas fa-user-shield</v-icon>
            </template>
            <template v-slot:[`item.team.playerNames`]="{ item }">
              <table class="nested">
                <tr  v-for="(player) in item.team.players" :key="player.id">
                  <td class="text-left">
                    <template v-if="view.adminAndRefs && hasAgreements">
                      <v-tooltip left v-for="(a, i) in tournament.getPlayerAgreements(player.playerProfileId, item.division.id)" :key="`${player.playerProfileId}-${item.division.id}-${i}`">
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-1" x-small :color="a.status === 'Signed' ? 'success' : 'error'" v-on="on" @click.stop="agreementClick(player.playerProfileId, a)">
                            fas fa-{{ a.status === 'Unsent' ? 'exclamation-circle' : 'signature' }}
                          </v-icon>
                        </template>
                        <span>{{a.name}} {{a.status}}</span>
                      </v-tooltip>
                    </template>
                    <v-btn
                       v-if="playerCheckIn"
                      :color="player.checkedIn ? 'success' : 'grey'"
                      text icon x-small
                      @click.stop="checkInPlayer(item.team, player)"
                      class="mr-3"
                    >
                      <v-icon>{{player.checkedIn ? 'fas fa-check-square' : 'far fa-square'}}</v-icon>
                    </v-btn>
                    <player-link
                      :player="player"
                      :key="player.id"
                      :emitter="true"
                      @link-click="selectTeam(item.team, player.playerProfileId)"
                    ></player-link>
                    <v-badge inline color="primary" v-if="item.division.props.includes('useCaptains') && item.team.isCaptain(player.playerProfileId)" class="ma-0">
                      <span slot="badge">C</span>
                    </v-badge>
                  </td>
                </tr>
              </table>
            </template>
            <template v-slot:[`item.schedule`]="{ item }">
              <v-btn color="color3 color3Text--text" fab small @click.stop="selectTeam(item.team)">
                <v-icon>fas fa-clipboard-list</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.team.checkedIn`]="{item}">
              <v-btn
                :color="item.team.checkedIn ? 'success' : 'grey'"
                text icon
                @click.stop="checkIn(item.team)"
              >
                <v-icon>{{item.team.checkedIn ? 'fas fa-check-square' : 'far fa-square'}}</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.shirts`]="{item}">
              {{getShirtSizes(item.team) | formatArray}}
            </template>
            <template v-slot:[`item.team.unix`]="{item}">
              <v-icon :color="getDrinks(item.team) ? 'success' : 'error'">fas fa-ticket</v-icon>
              <div>{{item.team.dtCreated.format('L LT')}}</div>
            </template>
          </v-data-table>
          <!-- CARD VIEW -->
          <player-list
            v-else
            :players="playerList"
          ></player-list>
        </v-col>
        <v-col class="text-right pa-0" cols="12" v-if="view.adminAndRefs">
          <team-message-button
            :tournamentId="tournament.id"
            :teams="notifyItems"
            :xs="true"
          ></team-message-button>
          <v-btn
            v-if="smsEnabled"
            color="color3"
            x-small text
            @click.stop="confirmSmsCheckin"
            :loading="smsing"
          >
            Send Check-in Via SMS
          </v-btn>
          <v-btn
            v-if="hasAgreements"
            color="color3"
            x-small text
            @click.stop="tournament.getAgreementStatus"
          >
            Refresh Player Agreements
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <vbl-confirm-dialog
      :ask="confirmSms"
      question="Send check in links via SMS?"
      :loading="smsing"
      @deny="confirmSms = false"
      @confirm="doSmsCheckIn"
    ></vbl-confirm-dialog>
    <player-agreement-dialog
      :playerProfileId="agreeInfo.playerProfileId"
      :agreementId="agreeInfo.agreementId"
      :agreement="agreeInfo.agreement"
      color="none"
      ref="agreeDialog"
      :showPlayer="true"
    ></player-agreement-dialog>
  </div>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin.js'
import { mapGetters } from 'vuex'
import PlayerLink from '@/components/Player/PlayerLink.vue'
import moment from 'moment'
import PlayerList from '@/components/Player/PlayerCardsList'
import flatten from '@/helpers/ArrayFlatten'
const TeamMessageButton = () => import('@/components/Notifications/TeamMessageButton')
const PlayerAgreementDialog = () => import('@/components/Agreements/PlayerAgreementDialog')

export default {
  props: ['search', 'loading', 'filter', 'hideIn'],
  mixins: [RouteMixin],
  data () {
    return {
      page: { itemsPerPage: 25, sortBy: ['division.name'] },
      saving: false,
      selectedTeam: null,
      dialog: false,
      teamTab: null,
      regData: null,
      shirtSizes: null,
      timelines: false,
      next: [],
      listView: true,
      playerCheckIn: false,
      book: false,
      confirmSms: false,
      smsing: false,
      notifyItems: [],
      agreeInfo: {
        playerProfileId: null,
        agreement: null,
        agreementId: null,
        get valid () {
          return !!(this.playerProfileId && this.agreement && this.agreementId)
        }
      },
      payData: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'user']),
    teams () {
      let base = this.view.adminAndRefs ? this.tournament.teamsOverview : this.tournament.teamsOverview.filter(f => f.public)
      if (this.book) {
        base = base.filter(f => f.team.hasBookmarkedPlayer(this.user))
      }
      if (this.next.length) {
        base.forEach(b => {
          const n = this.next.find(f => f.id === b.team.id)
          b.next = n ? n.next : 'NA'
        })
      }
      if (this.filter) {
        base = base.filter(f => f.division.id === this.filter)
      }
      if (this.hideIn) {
        base = base.filter(f => !f.team.checkedIn)
      }
      return base
    },
    showShirts () {
      return this.view.adminAndRefs && this.regData && this.tournament.props.includes('shirts')
    },
    headers () {
      const heads = [
        { text: 'Division', value: 'division.name', show: true },
        { text: 'Team', value: 'team.name', show: true },
        { text: 'Players', value: 'team.playerNames', show: true },
        { text: 'Field', value: 'team.field', show: this.tournament.props.includes('fields') },
        { text: 'Team Check In', value: 'team.checkedIn', align: 'center', show: this.view.adminAndRefs },
        { text: 'Schedule', value: 'schedule', show: true },
        { text: 'Next', value: 'next', sortable: false, show: this.timelines },
        { text: 'Shirts', value: 'shirts', sortable: false, show: this.showShirts },
        { text: 'Drinks', value: 'team.unix', show: false }
      ]

      return heads.filter(f => f.show)
    },
    isLoading () {
      return this.loading || this.saving
    },
    playerList () {
      return flatten(
        this.teams.map(t => {
          return t.team.players.map(p => {
            return {
              division: t.division.name,
              player: p,
              teamId: t.team.id
            }
          })
        })
      )
    },
    hasAgreements () {
      return this.tournament.agreementStatuses.length
    },
    smsEnabled () {
      return this.tournament.smsEnabled
    },
    bookmarks () {
      return this.$route.query.bookmarks
    },
    hasBookmarks () {
      return this.user && this.teams && this.teams.filter(f => f.team.hasBookmarkedPlayer(this.user)).length > 0
    },
    payLater () {
      return this.view.adminAndRefs && this.tournament.organization.allowPayLater
    }
  },
  methods: {
    confirmSmsCheckin () {
      this.confirmSms = true
    },
    doSmsCheckIn () {
      this.smsing = true
      this.confirmSms = false
      this.$VBL.tournament.checkin.sendSms(this.tournamentId)
        .then(r => console.log('sms sent'))
        .catch(e => console.log(e.response))
        .finally(() => {
          this.smsing = false
        })
    },
    checkIn (team) {
      team.checkedIn = !team.checkedIn
      this.saving = true
      this.$VBL.team.checkIn(team.id, team.checkedIn)
        .catch(err => {
          console.log(err.response)
          team.checkedIn = !team.checkedIn
        })
        .finally(() => { this.saving = false })
    },
    checkInPlayer (team, player) {
      player.checkedIn = !player.checkedIn
      this.saving = true
      this.$VBL.team.checkIn(team.id, player.checkedIn, null, player.id)
        .catch(err => {
          console.log(err.response)
          player.checkedIn = !player.checkedIn
        })
        .finally(() => { this.saving = false })
    },
    selectTeam (team, playerId) {
      // console.log(team)
      // this.$store.commit(mutations.SET_TIMELINE_TEAM, {teamId: team.id, tab: playerId ? `${playerId}` : null})
      this.teamClick(team.id, playerId)
    },
    teamClick (id, playerId) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id, playerId: playerId } })
    },
    onCloseClick () {
      this.dialog = false
    },
    isPending (id) {
      const a = this.payData.find(f => f.teamId === id)
      return a && a.pending && a.pending > 0
    },
    isQuickAdd (id) {
      const a = this.payData.find(f => f.teamId === id)
      return a && a.quickAdd
    },
    getPayStatus () {
      this.saving = true
      this.$VBL.tournament.getRawRegistrationData(this.tournament.id)
        .then((response) => {
          const data = response.data
          data.forEach((d) => {
            const x = ['Pending', 'Payment Requested'].includes(d.paymentStatus)
            d.pending = x ? (+d.amount - d.externalAmount) : 0
            d.amount = x ? 0 + d.externalAmount : d.amount
          })
          this.payData = data
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => { this.saving = false })
    },
    getRegistrationData () {
      this.saving = true
      this.$VBL.tournament.getRegistrationData(this.tournament.id)
        .then((response) => {
          this.regData = response.data
          this.loadShirts()
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.saving = false
        })
    },
    getShirtSizes (team) {
      const teamData = this.shirtSizes ? this.shirtSizes.find(f => f.id === team.id) : null
      return teamData ? teamData.sizes : []
    },
    getDrinks (team) {
      // return team.dtCreated.format('L LT')
      // return moment('2019-09-16 05Z').format('L LT')
      return team.dtCreated.isBefore(moment('2019-09-16 05Z'))
    },
    loadShirts () {
      const teamSizes = []
      this.teams.map(t => t.team).forEach(team => {
        const sizes = ['XS', 'S', 'M', 'L', 'XL', '2X'].map(m => {
          return {
            name: m,
            count: 0
          }
        })
        team.players.forEach(m => {
          var data = this.regData.find(f => f.playerProfileId === m.playerProfileId && !!f.shirtSize)
          if (!data) return
          const name = (data.shirtSize || 'NA').toUpperCase()
          if (name === 'NA') {
            console.log(data)
          }
          let x = sizes.find(f => f.name === name)
          if (!x) {
            x = {
              name: name,
              count: 0
            }
            sizes.push(x)
          }
          x.count++
        })
        teamSizes.push({ id: team.id, sizes: sizes.filter(f => f.count).map(m => `${m.count} ${m.name}${m.count > 1 ? 's' : ''}`) })
      })
      this.shirtSizes = teamSizes
    },
    loadTimelines () {
      this.saving = true
      this.next = []
      const base = this.view.adminAndRefs ? this.tournament.teamsOverview : this.tournament.teamsOverview.filter(f => f.public)
      base.forEach(t => {
        const timeline = this.tournament.getTimeline(t.team.id)
        const nextRound = timeline.rounds.find(r => {
          return r.matches.find(m => !m.status)
        })
        if (nextRound) {
          const next = nextRound.matches.find(m => !m.status)
          if (next) {
            this.next.push({
              id: t.team.id,
              next: `${next.what} ${next.where || 'NA'}`
            })
          }
        }
      })
      this.saving = false
      this.timelines = true
    },
    getFiltered (e) {
      this.notifyItems = e
    },
    agreementClick (playerId, a) {
      console.log('here')
      console.log(playerId)
      console.log(a)
      this.agreeInfo.playerProfileId = playerId
      this.agreeInfo.agreement = a.name
      this.agreeInfo.agreementId = a.id
      this.$refs.agreeDialog.open()
    }
  },
  watch: {
    'view.adminAndRefs': function (val) {
      if (val) {
        this.tournament.getAgreementStatus()
        this.tournament.props.includes('shirts') && this.getRegistrationData()
        this.payLater && this.getPayStatus()
      }
    }
  },
  mounted () {
    if (this.view.adminAndRefs) {
      this.tournament.getAgreementStatus()
      this.tournament.props.includes('shirts') && this.getRegistrationData()
      this.payLater && this.getPayStatus()
    }
    // this.playerCheckIn = !!(this.tournament.activePlayers && this.tournament.activePlayers.find(f => f.checkedIn))
    this.book = this.bookmarks
  },
  components: {
    PlayerLink,
    PlayerList,
    TeamMessageButton,
    PlayerAgreementDialog
  }
}
</script>

<style>
.nested th, .nested td {
  padding: 0 !important;
  height: auto !important;
  border-bottom: none !important;
}
.nested th {
  text-align: right;
}
.checkedIn {
  background: #eee
}
</style>
