import { render, staticRenderFns } from "./ProfileCard.vue?vue&type=template&id=6c1a67ed&scoped=true&"
import script from "./ProfileCard.vue?vue&type=script&lang=js&"
export * from "./ProfileCard.vue?vue&type=script&lang=js&"
import style0 from "./ProfileCard.vue?vue&type=style&index=0&id=6c1a67ed&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c1a67ed",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VCardText,VExpandTransition,VFabTransition,VHover,VIcon,VImg,VResponsive,VSpacer,VTooltip,VWindow,VWindowItem})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
