<template>
  <v-container fluid v-if="loading && items.length === 0">
    <v-row dense>
      <v-progress-circular :value="20"></v-progress-circular>
    </v-row>
  </v-container>
  <v-data-iterator
    v-else
    :items="items"
    :sort-by="sort.by"
    :sort-desc="sort.desc"
    :items-per-page="infinite ? -1 : itemsPerPage"
    :footer-props="{itemsPerPageOptions: itemsPerPageArray}"
    multi-sort
    :search="search"
    :hide-default-footer="hideFooter"
  >
    <template v-slot:default="props">
      <v-row dense>
        <v-col
          v-for="(item) in props.items"
          :key="item.id"
          cols="12" sm="6" md="4" xl="3">
          <v-card v-if="item.loading">
            <v-responsive :aspect-ratio="1">
              <v-skeleton-loader
                type="image"
                height="100%"
              ></v-skeleton-loader>
            </v-responsive>
          </v-card>
          <profile-card
            v-else
            :player="item"
            size="100%"
            @blocked="onBlocked"
            :key="item.ppid"
          ></profile-card>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import ProfileCard from '@/components/Player/ProfileCard'

export default {
  props: {
    players: Array,
    loading: Boolean,
    search: String,
    sort: {
      type: Object,
      default: () => {
        return {
          by: null,
          desc: null
        }
      }
    },
    itemsPerPage: {
      type: Number,
      default: () => 20
    },
    itemsPerPageArray: {
      type: Array,
      default: () => [20, 40, 80, -1]
    },
    canHideFooter: Boolean,
    infinite: Boolean
  },
  data () {
    return {
    }
  },
  computed: {
    items () {
      const skeleton = []
      if (this.loading) {
        skeleton.push(...[...Array(1).keys()].map(m => {
          return { id: m, loading: true }
        }))
      }

      return this.players && this.players.length ? this.players : skeleton
    },
    hideFooter () {
      return this.infinite || (this.canHideFooter && this.players && this.players.length < this.itemsPerPage)
    }
  },
  methods: {
    onBlocked (msg) {
      this.$emit('blocked', msg)
    },
    onIntersect (entries, observer) {
      if (this.infinite && entries[0].isIntersecting) this.$emit('intersected')
    }
  },
  components: {
    ProfileCard
  }
}
</script>

<style scoped>
>>> .v-skeleton-loader__image {
  height: 100%;
}
</style>
