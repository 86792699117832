<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- BREADCRUMBS -->
    <v-row dense>
      <v-col cols="12">
        <v-breadcrumbs class="py-0" :class="{'pl-0': $vuetify.breakpoint.smAndUp}" :items="breadcrumbs">
          <v-icon slot="divider" class="xsmall">fas fa-chevron-right</v-icon>
          <template slot="item" slot-scope="props">
            <v-btn
              color="color3"
              class="pa-0 ma-0 xsmall"
              style="min-width: 0"
              text
              :to="props.item.to"
              exact
              small
              :disabled="props.item.disabled"
            >{{props.item.text}}</v-btn>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <!-- MAIN CONTENT -->
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" :dark="color1IsDark" extended extension-height="58">
            <v-toolbar-title>{{tournament.name}} Teams</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="color3Text color3--text"
              fab
              small
              @click.stop="loadTimelineClick"
              class="mr-2"
              v-if="fullyHydrated"
            >
              <v-icon>fas fa-clipboard-list-check</v-icon>
            </v-btn>
            <quick-add-dialog
              v-if="view.admin"
              :tournament="tournament"
              fab
            ></quick-add-dialog>
            <template v-slot:extension>
              <v-text-field
                color="color3"
                append-icon="fas fa-search"
                label="Search"
                hide-details
                v-model="search"
                solo-inverted
                clearable
              ></v-text-field>
            </template>
          </v-toolbar>
          <v-container fluid class="pt-0" v-if="view.adminAndRefs">
            <v-row dense>
              <v-col cols="12" class="subheading d-flex" v-if="!allComplete">
                Check-in status: {{teamsSum ? `${teamsSum.checkedIn} of ${teamsSum.teams} (${teamsSum.teams - teamsSum.checkedIn} remaining)` : ''}}
                <v-switch
                  label="Hide Checked In"
                  v-model="hideIn"
                  dense hide-details
                  class="mt-0 shrink ml-3"
                  color="success"
                ></v-switch>
              </v-col>
              <v-col cols="12" v-if="!allComplete">
                <v-chip
                  v-for="d in divisions" :key="d.id"
                  @click.stop="filterByChip(d.id)"
                  :color="d.complete ? 'green' : 'red'"
                  text-color="white"
                  class="mr-1"
                >
                  {{d.name}} &nbsp;<b class="ml-1">{{d.checkedIn}}/{{d.teams}}</b>
                  <v-icon v-if="false && d.complete" right>fas fa-check-circle</v-icon>
                  <v-icon v-if="false && !d.complete" right>fas fa-exclamation-circle</v-icon>
                  <v-icon right v-if="filter === d.id" small>fas fa-filter</v-icon>
                </v-chip>
              </v-col>
              <v-col cols="12" v-else>
                <v-alert type="success" :value="true" dismissible>
                  Everyone is checked in!
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
          <team-list
            :search="search"
            :filter="filter"
            ref="teams"
            :hideIn="hideIn"
          ></team-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin.js'
import TeamList from '@/components/Tournament/Teams/TournamentTeamList.vue'
import QuickAddDialog from '@/components/Tournament/Registration/QuickAddDialog.vue'
import { mapGetters } from 'vuex'
import sum from 'lodash.sum'

export default {
  mixins: [RouteMixin],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Tournament Home',
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: 'Teams',
          exact: true,
          disabled: true
        }
      ],
      search: null,
      dialog: false,
      checkingIn: false,
      badLink: false,
      filter: null,
      hideIn: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'color1IsDark']),
    fullyHydrated () {
      return this.tournament.publicDivisions.filter(f => !f.hydrated).length === 0
    },
    divisions () {
      return this.tournament.publicDivisions.map(d => {
        return {
          id: d.id,
          name: d.name,
          complete: d.checkinComplete,
          teams: d.activeTeams.length,
          checkedIn: d.activeTeams.filter(f => f.checkedIn).length
        }
      })
    },
    teamsSum () {
      return this.divisions && {
        teams: sum(this.divisions.map(m => m.teams)),
        checkedIn: sum(this.divisions.map(m => m.checkedIn))
      }
    },
    allComplete () {
      return this.divisions.filter(f => !f.complete).length === 0
    },
    checkin () {
      return this.$route.query.checkin
    },
    ttid () {
      return this.$route.query.ttid
    },
    regId () {
      return this.$route.query.trid
    },
    email () {
      return this.$route.query.e
    }
  },
  methods: {
    filterByChip (id) {
      if (this.filter === id) {
        this.filter = null
      } else {
        this.filter = id
      }
    },
    doCheckIn () {
      const dto = this.$route.query
      dto.tournamentId = this.tournament.id
      this.$VBL.team.onlineCheckIn(dto)
        .then(r => {

        })
        .catch(e => {
          this.badLink = true
          console.log(e.response.data)
        })
    },
    loadTimelineClick () {
      if (this.$refs.teams) {
        this.$refs.teams.loadTimelines()
      }
    },
    getSanctioning () {
    }
  },
  components: {
    TeamList,
    QuickAddDialog
  },
  mounted () {
    if (this.checkin) {
      this.dialog = true
      this.doCheckIn()
    }
  }
}
</script>
