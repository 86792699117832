<template>
  <v-btn
    text icon
    @click.stop="bookMe"
    :loading="booking"
  >
    <v-icon :color="color">
      fa{{bookmarked ? 's' : 'r'}} fa-heart
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['ppid', 'color', 'type'],
  data () {
    return {
      booking: false
    }
  },
  computed: {
    ...mapGetters(['user', 'darkColor', 'theme']),
    bookmarked () {
      return this.user && this.user.bookmarks && this.user.bookmarks.find(f => f.id === this.ppid && f.type === this.type)
    }
  },
  methods: {
    bookMe () {
      if (!this.user) {
        this.$emit('blocked', 'You must be logged in to favorite a player')
        return
      }
      this.booking = true
      this.$VBL.user.toggleBookmark(this.type, this.ppid)
        .catch(e => console.log(e.response))
        .finally(() => { this.booking = false })
    }
  }
}
</script>
