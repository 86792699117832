var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[(_vm.view.public && !_vm.teams.length)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('message-card',[_c('h2',{staticClass:"ma-12 text-center"},[_c('v-avatar',{attrs:{"color":"red"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("fas fa-eye-slash")])],1),_c('br',{staticClass:"hidden-md-and-up"}),_vm._v(" The tournament host has not published the teams yet. ")],1)])],1)],1):_c('v-row',{attrs:{"dense":""}},[(_vm.hasBookmarks)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Favorite Players Only","color":"success","dense":"","hide-details":""},model:{value:(_vm.book),callback:function ($$v) {_vm.book=$$v},expression:"book"}})],1):_vm._e(),(_vm.view.adminAndRefs)?_c('v-col',{staticClass:"text-right pa-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"color3","x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();_vm.playerCheckIn = !_vm.playerCheckIn}}},[_vm._v(" "+_vm._s(_vm.playerCheckIn ? 'hide' : 'show')+" Player check-in ")])],1):_vm._e(),_c('v-col',{key:_vm.payData.length,attrs:{"cols":"12"}},[(_vm.listView)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.teams,"headers":_vm.headers,"loading":_vm.isLoading,"options":_vm.page,"search":_vm.search,"hide-default-header":_vm.$vuetify.breakpoint.xs,"dense":""},on:{"update:options":function($event){_vm.page=$event},"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"slot":"progress","color":"color3","indeterminate":""},slot:"progress"})]},proxy:true},{key:"item.team.name",fn:function(ref){
var item = ref.item;
return [(_vm.payLater && _vm.payData.length && _vm.isPending(item.team.id))?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"error"}},[_vm._v("fas fa-sack-dollar")]):_vm._e(),_vm._v(" "+_vm._s(item.team.name)+" "),(_vm.payLater && _vm.payData.length && _vm.isPending(item.team.id) && _vm.isQuickAdd(item.team.id))?_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"error"}},[_vm._v("fas fa-user-shield")]):_vm._e()]}},{key:"item.team.playerNames",fn:function(ref){
var item = ref.item;
return [_c('table',{staticClass:"nested"},_vm._l((item.team.players),function(player){return _c('tr',{key:player.id},[_c('td',{staticClass:"text-left"},[(_vm.view.adminAndRefs && _vm.hasAgreements)?_vm._l((_vm.tournament.getPlayerAgreements(player.playerProfileId, item.division.id)),function(a,i){return _c('v-tooltip',{key:((player.playerProfileId) + "-" + (item.division.id) + "-" + i),attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"x-small":"","color":a.status === 'Signed' ? 'success' : 'error'},on:{"click":function($event){$event.stopPropagation();return _vm.agreementClick(player.playerProfileId, a)}}},on),[_vm._v(" fas fa-"+_vm._s(a.status === 'Unsent' ? 'exclamation-circle' : 'signature')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(a.name)+" "+_vm._s(a.status))])])}):_vm._e(),(_vm.playerCheckIn)?_c('v-btn',{staticClass:"mr-3",attrs:{"color":player.checkedIn ? 'success' : 'grey',"text":"","icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.checkInPlayer(item.team, player)}}},[_c('v-icon',[_vm._v(_vm._s(player.checkedIn ? 'fas fa-check-square' : 'far fa-square'))])],1):_vm._e(),_c('player-link',{key:player.id,attrs:{"player":player,"emitter":true},on:{"link-click":function($event){return _vm.selectTeam(item.team, player.playerProfileId)}}}),(item.division.props.includes('useCaptains') && item.team.isCaptain(player.playerProfileId))?_c('v-badge',{staticClass:"ma-0",attrs:{"inline":"","color":"primary"}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v("C")])]):_vm._e()],2)])}),0)]}},{key:"item.schedule",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"color3 color3Text--text","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectTeam(item.team)}}},[_c('v-icon',[_vm._v("fas fa-clipboard-list")])],1)]}},{key:"item.team.checkedIn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":item.team.checkedIn ? 'success' : 'grey',"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.checkIn(item.team)}}},[_c('v-icon',[_vm._v(_vm._s(item.team.checkedIn ? 'fas fa-check-square' : 'far fa-square'))])],1)]}},{key:"item.shirts",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatArray")(_vm.getShirtSizes(item.team)))+" ")]}},{key:"item.team.unix",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getDrinks(item.team) ? 'success' : 'error'}},[_vm._v("fas fa-ticket")]),_c('div',[_vm._v(_vm._s(item.team.dtCreated.format('L LT')))])]}}],null,true)}):_c('player-list',{attrs:{"players":_vm.playerList}})],1),(_vm.view.adminAndRefs)?_c('v-col',{staticClass:"text-right pa-0",attrs:{"cols":"12"}},[_c('team-message-button',{attrs:{"tournamentId":_vm.tournament.id,"teams":_vm.notifyItems,"xs":true}}),(_vm.smsEnabled)?_c('v-btn',{attrs:{"color":"color3","x-small":"","text":"","loading":_vm.smsing},on:{"click":function($event){$event.stopPropagation();return _vm.confirmSmsCheckin($event)}}},[_vm._v(" Send Check-in Via SMS ")]):_vm._e(),(_vm.hasAgreements)?_c('v-btn',{attrs:{"color":"color3","x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.tournament.getAgreementStatus($event)}}},[_vm._v(" Refresh Player Agreements ")]):_vm._e()],1):_vm._e()],1)],1),_c('vbl-confirm-dialog',{attrs:{"ask":_vm.confirmSms,"question":"Send check in links via SMS?","loading":_vm.smsing},on:{"deny":function($event){_vm.confirmSms = false},"confirm":_vm.doSmsCheckIn}}),_c('player-agreement-dialog',{ref:"agreeDialog",attrs:{"playerProfileId":_vm.agreeInfo.playerProfileId,"agreementId":_vm.agreeInfo.agreementId,"agreement":_vm.agreeInfo.agreement,"color":"none","showPlayer":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }